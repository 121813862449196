import { currentTimestamp, deleteItemByIndex } from "./helpers"

export default ({ withPagination, withSorting, withFilters }) => {
  let mutations = { ...ITEMS_MUTATIONS, ...MISC_MUTATIONS }

  if (withPagination) {
    mutations = { ...mutations, ...PAGINATION_MUTATIONS }
  }

  if (withSorting) {
    mutations = { ...mutations, ...SORTING_MUTATIONS }
  }

  if (withFilters) {
    mutations = { ...mutations, ...FILTERS_MUTATIONS }
  }

  return mutations
}

const MISC_MUTATIONS = {
  RESET_STATE(state, newState) {
    Object.assign(state, newState)
  }
}

const ITEMS_MUTATIONS = {
  SET_ITEMS(state, payload) {
    state.items = payload
  },

  SET_SELECTED_ITEMS(state, payload) {
    state.selectedItems = payload
  },

  SET_ITEM_BY_INDEX(state, { item, index }) {
    item._updated = true
    state.items.splice(index, 1, item)
  },

  DELETE_ITEM_BY_ID(state, id) {
    const index = state.items.map(({ id }) => id).indexOf(id)
    deleteItemByIndex(state, index)
  },

  DELETE_ITEM_BY_INDEX(state, index) {
    deleteItemByIndex(state, index)
  },

  SET_LOADING(state, payload) {
    state.loading = !!payload
  },

  ADD_ITEM(state, { item, prepend = false }) {
    item._addedAt = `t_${currentTimestamp()}`

    prepend ? state.itemsAdded.unshift(item) : state.itemsAdded.push(item)
  },

  SET_ADDED_ITEM_BY_INDEX(state, { item, index }) {
    item._updated = true
    state.itemsAdded.splice(index, 1, item)
  },

  DELETE_ADDED_ITEM_BY_INDEX(state, index) {
    const deleted = state.itemsAdded.splice(index, 1).filter(({ id }) => id)
    state.itemsDeleted = [...state.itemsDeleted, ...deleted]
  },

  RESET_ITEMS(state) {
    state.items = []
  },

  RESET_ITEMS_ADDED(state) {
    state.itemsAdded = []
  },

  RESET_ITEMS_DELETED(state) {
    state.itemsDeleted = []
  }
}

const PAGINATION_MUTATIONS = {
  SET_PAGINATION_DATA(state, pagination) {
    state.pagination = pagination
  },

  RESET_CURRENT_PAGE(state) {
    state.pagination = { ...state.pagination, current_page: 1 }
  }
}

const SORTING_MUTATIONS = {
  SET_SORTING(state, sorting) {
    state.sorting = { ...state.sorting, ...sorting }
  }
}

const FILTERS_MUTATIONS = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters }
  }
}
